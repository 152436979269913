.properties-list {
    padding-left: 20px;
    padding-right: 20px;
    clear: right;
    /*background-color: #e8ecef;*/
    .properties-found {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .property {
        background: #ffffff;
        margin: 0 -4px 24px;
        border: 1px solid transparent;
        position: relative;
        box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;

        .property-title {
            padding: 9px 16px 0;
            margin: 0;
            line-height: 1.4;
            font-size: 1.2rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            span {
                color: #222;
                font-weight: 500;
            }
        }
        .feature-image {
            margin: 7px 7px 0;
            background-position: center center;
            background-size: cover;
            position: relative;
            font-size: 14px;
            .carousel {
                .carousel-indicators {
                    display: none;
                }
                .carousel-inner {
                    min-height: 297px;
                }
                .carousel-item {
                    background: #fff;
                    cursor: pointer;

                    .image {
                        width: 436px;
                        height: 297px;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                    }
                }
                .carousel-control-prev, 
                .carousel-control-next {
                    z-index: 3;
                }
                .carousel-control-prev-icon,
                .carousel-control-next-icon {
                    filter: drop-shadow(0 0 2px black);
                }
                .crop-image {
                    object-fit: cover;
                }
            }
            .label-property-type {
                position: absolute;
                font-weight: bold;
                color: #fff;
                font-size: 13px;
                float: none;
                top: 2px;
                right: 0;
                z-index: 1;
                .RsStatus {
                    background-color: #92278f;
                }
                .ForSale {
                    background-color: #ec485f;
                }
                .LongTermRental {
                    background-color: #2aa81d;
                }
                .ShortTermRental {
                    background-color: #218de0;
                }
                span {
                    padding: 1px 7px;
                }
                span.count-image {
                    color: white;
                }
            }
            .property-sizes-area {
                position: absolute;
                display: inline-block;
                bottom: 0;
                width: 100%;
                padding: 20px 10px 5px 0;
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
                font-size: 14px;
                .property-sizes {
                    margin-left: 10px;
                    display: inline-block;
                    color: white;
                    i {
                        padding-right: 4px;
                    }
                }
                .image-icon {
                    margin-left: 10px;
                    display: inline-block;
                    color: white;
                }
            }
        }
        .property-info {
            position: relative;
            .property-title {
                padding: 9px 16px 0;
                margin: 0;
                line-height: 1.4;
                font-size: 1.2rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                cursor: pointer
            }
            .property-price {
                font-weight: 600;
                color: #333333;
                font-size: 24px;
                margin-left: 0;
                padding: 0 16px 7px 16px;
                cursor: text;
            }
            .extends-info {
                font-weight: 400;
                font-size: 18px;
                line-height: 1.7rem;
                margin-bottom: 0;
                min-height: 29px;

                .property-baths,
                .property-beds {
                    font-size: 18px;
                    color: #333;
                    display: inline-block;
                    padding-left: 16px;
                }

                .prop-ref {
                    font-size: 14px;
                    font-family: Arial, sans-serif;
                    color: #999;
                    margin-right: 10px;
                    float: right;
                }

                .published-date {
                    font-size: 12px;
                    color: #999;
                    margin-right: 5px;
                    float: right;
                }
            }
            .property-agency-area {
                display: none;
            }
        }
        .property-disabled-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: #fff;
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
